import React, { useState, useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import ManagedITServices from './Images/S/Managed-IT-S.jpg'
import RevenueCycleManagement from './Images/S/Revenue-Cycle-Management-S.jpg'
import EnterpriseIT from './Images/S/Enterprise-IT-S.jpg'
import ElectronicHealthRecord from './Images/S/Electronic-Health-Record-S.jpg'
import HealthcareStaffAugmentation from './Images/S/Healthcare-Staff-Augmentation-S.jpg'
import Marketing from './Images/S/marketing-s.jpg'

function SliderDetail() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false); // State to track if the image has loaded

  const slides = [
    { id: 1, image: ManagedITServices, title: "Managed IT Services", cardPara: "Elevate your business efficiency with our comprehensive managed IT solutions.", readMore: "/managed-it-services" },
    { id: 2, image: RevenueCycleManagement, title: "Revenue Cycle Management", cardPara: "Maximize revenue streams and streamline financial processes with our tailored management services.", readMore: "/revenue-cycle-management" },
    { id: 3, image: EnterpriseIT, title: "Enterprise IT", cardPara: "Meet fluctuating demands with ease through our healthcare staff augmentation services.", readMore: "/enterprise-it" },
    { id: 4, image: ElectronicHealthRecord, title: "Electronic Health Record", cardPara: "Embrace the future of healthcare with our advanced EHR solutions.", readMore: "/electronic-health-record" },
    { id: 5, image: HealthcareStaffAugmentation, title: "Healthcare Staff Augmentation", cardPara: "Scale your operations efficiently with our enterprise IT services.", readMore: "/healthcare-staff-augmentation" },
    { id: 6, image: Marketing, title: "Marketing", cardPara: "Scale your operations efficiently with our enterprise IT services.", readMore: "/" },
  ];

  useEffect(() => {
    // Preload the first image
    const image = new Image();
    image.src = slides[0].image;
    image.onload = () => setImageLoaded(true);

    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 5000); // Change slide every 5 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, [slides]);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="slider-container" style={{ minHeight: imageLoaded ? "auto" : "600px" }}>
      {!imageLoaded && <div className="loading-spinner">Loading...</div>} {/* Show loading spinner */}
      <div className={`slider ${imageLoaded ? "visible" : "hidden"}`}>
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            <img
              src={slide.image}
              alt={slide.title}
              className="sliderImage"
              onLoad={() => setImageLoaded(true)} // Set imageLoaded to true when the image loads
            />
            <div className="overlay"></div> {/* Add overlay */}
          </div>
        ))}
        <div className="slider-dots">
          {slides.map((slide, index) => (
            <span
              key={slide.id}
              className={`dot ${index === currentSlide ? "active" : ""}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
        <div className="white-line"></div>
      </div>
      <div className="slider-cards">
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`card ${index === currentSlide ? "active" : ""}`}
            style={{
              left: `${index * 12}%`, // Adjust the position based on the index
              display: index === currentSlide ? "block" : "none"
            }}
          >
            <div className="card-content">
              <h1>{slide.title}</h1>
              <p>{slide.cardPara}</p>
              <Link to={slide.readMore} className="cta-button">Read More</Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SliderDetail;
