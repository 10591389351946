import React from "react";
import { FaPlus } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const ProjectAreaOne = () => {
  return (
    <div
      className="project-section pd-top-40 "
      style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center">
              {/* <h6 className="sub-title">WHAT WE OFFER</h6> */}
              <h2 className="title">
                Discover Customized <span> IT Solutions </span> and other{" "}
                <span> Services </span>
              </h2>
            </div>
          </div>
        </div>
        {/* <nav className='text-center'>
          <div
            className='nav nav-tabs project-nav-tab d-inline-flex mb-5'
            id='nav-tab'
            role='tablist'
          >
            <button
              className='nav-link active'
              id='nav-tabs1-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-tabs1'
              type='button'
              role='tab'
              aria-controls='nav-tabs1'
              aria-selected='true'
            >
              View All
            </button>
            <button
              className='nav-link'
              id='nav-tabs2-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-tabs2'
              type='button'
              role='tab'
              aria-controls='nav-tabs2'
              aria-selected='false'
            >
              Softwate
            </button>
            <button
              className='nav-link'
              id='nav-tabs3-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-tabs3'
              type='button'
              role='tab'
              aria-controls='nav-tabs3'
              aria-selected='false'
            >
              New Project
            </button>
            <button
              className='nav-link'
              id='nav-tabs4-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-tabs4'
              type='button'
              role='tab'
              aria-controls='nav-tabs4'
              aria-selected='false'
            >
              Digitals
            </button>
            <button
              className='nav-link'
              id='nav-tabs5-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-tabs5'
              type='button'
              role='tab'
              aria-controls='nav-tabs5'
              aria-selected='false'
            >
              Hardware
            </button>
          </div>
        </nav> */}
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-tabs1"
            role="tabpanel"
            aria-labelledby="nav-tabs1-tab"
          >
            <div className="row justify-content-center">
              {/* Managed IT Service */}
              <div className="col-md-4 services-box">
                <div className="single-project-inner">
                  <h2 className="centered">Managed IT Services</h2>
                  <img
                    src="assets/img/gallery/managed-it-service.jpg"
                    alt="Managed IT Service"
                  />
                  <div className="overlay"></div> {/* Add overlay */}
                  <div className="details">
                    {/* <span>
                      <FaPlus />
                    </span> */}
                    <p>
                      Elevate your business efficiency with our comprehensive
                      managed IT solutions.
                    </p>

                    <Link className="read-more-text" to="/managed-it-services" onClick={scrollToTop}>
                      Read More <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
              {/* Revenue Cycle Management */}
              <div className="col-md-4 services-box">
                <div className="single-project-inner">
                  <h2 className="centered">Revenue Cycle Management</h2>
                  <img
                    src="assets/img/gallery/revenue-cycle-management.jpg"
                    alt="Revenue Cycle Management"
                  />
                  <div className="overlay"></div> {/* Add overlay */}
                  <div className="details">
                    {/* <span>
                      <FaPlus />
                    </span> */}
                    <p>
                      Maximize revenue streams and streamline financial
                      processes with our tailored management services.
                    </p>
                    <Link
                      className="read-more-text"
                      to="/revenue-cycle-management" 
                      onClick={scrollToTop}
                    >
                      Read More <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
              {/* Healthcare Staff Augmentation */}
              <div className="col-md-4 services-box">
                <div className="single-project-inner">
                  <h2 className="centered">Healthcare Staff Augmentation</h2>
                  <img
                    src="assets/img/gallery/healthcare-staff-augmentation.jpg"
                    alt="Healthcare Staff Augmentation"
                  />
                  <div className="overlay"></div> {/* Add overlay */}
                  <div className="details">
                    {/* <span>
                      <FaPlus />
                    </span> */}
                    <p>
                      Meet fluctuating demands with ease through our healthcare
                      staff augmentation services.
                    </p>

                    <Link
                      className="read-more-text"
                      to="/healthcare-staff-augmentation"
                      onClick={scrollToTop}
                    >
                      Read More <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              {/* EHR */}
              <div className="col-md-4 services-box">
                <div className="single-project-inner">
                  <h2 className="centered-2">Electronic Health Record</h2>
                  <img
                    src="assets/img/gallery/ehr.jpg"
                    alt="Electronic Health Record"
                  />
                  <div className="overlay"></div> {/* Add overlay */}
                  <div className="details">
                    {/* <span>
                      <FaPlus />
                    </span> */}
                    <p>
                      Embrace the future of healthcare with our advanced EHR
                      solutions.
                    </p>
                    <Link className="read-more-text" to="/ehr"
                    onClick={scrollToTop}>
                      Read More <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
              {/* Enterprise IT */}
              <div className="col-md-4 services-box">
                <div className="single-project-inner">
                  <h2 className="centered-3">Enterprise IT</h2>
                  <img
                    src="assets/img/gallery/enterprise-it.jpg"
                    alt="Enterprise IT"
                  />
                  <div className="overlay"></div> {/* Add overlay */}
                  <div className="details">
                    {/* <span>
                      <FaPlus />
                    </span> */}
                    <p>
                      Scale your operations efficiently with our enterprise IT
                      services.
                    </p> 
                    <Link className="read-more-text" to="/enterprise-it" onClick={scrollToTop}>
                      Read More <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
              {/* Marketing */}
              <div className="col-md-4 services-box">
                <div className="single-project-inner">
                  <h2 className="centered-3">Marketing</h2>
                  <img src="assets/img/gallery/marketing.jpg" alt="Marketing" />
                  <div className="overlay"></div> {/* Add overlay */}
                  <div className="details">
                    {/* <span>
                      <FaPlus />
                    </span> */}
                    <p>
                      Amplify your brand presence and reach your target audience
                      effectively with our strategic marketing services
                    </p>
                    <Link className="read-more-text" to="/marketing" onClick={scrollToTop}>
                      Read More <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className='tab-pane fade'
            id='nav-tabs2'
            role='tabpanel'
            aria-labelledby='nav-tabs2-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/1.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/5.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/6.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/3.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/4.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/8.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/2.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/7.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='tab-pane fade'
            id='nav-tabs3'
            role='tabpanel'
            aria-labelledby='nav-tabs3-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/1.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/5.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/6.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/2.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/7.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/3.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/4.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/8.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='tab-pane fade'
            id='nav-tabs4'
            role='tabpanel'
            aria-labelledby='nav-tabs4-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/2.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/7.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/3.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/4.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/8.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/1.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/5.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/6.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='tab-pane fade'
            id='nav-tabs5'
            role='tabpanel'
            aria-labelledby='nav-tabs5-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/1.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/5.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/6.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/2.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/7.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/3.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/4.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/8.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProjectAreaOne;
