import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast, Toaster } from "react-hot-toast";
import MapDetail from "./MapDetail";
import ReCAPTCHA from "react-google-recaptcha";
import contactBanner from './Images/contact-us-final-wp.webp'

const ContactMain = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      toast.error("Please complete the reCAPTCHA!");
      return;
    }

    emailjs
      .sendForm(
        "service_7uzhm0r", // YOUR_SERVICE_ID
        "template_x2g6v7a", // YOUR_TEMPLATE_ID
        form.current,
        "y_8tLXr9YjarjhSHW" // YOUR_USER_ID
      )
      .then(
        (result) => {
          if (result.status === 200) {
            toast.success("Message Sent Successfully!");
            form.current.reset(); // Reset the form fields
            setFormData({
              name: "",
              email: "",
              subject: "",
              message: "",
            });
          } else {
            toast.error("Failed to send message!");
          }
        },
        (error) => {
          toast.error("Failed to send message!");
          console.error("EmailJS error:", error);
        }
      );
  };

  return (
    <>
      {/* Contact details */}
      <div
        className="breadcrumb-area bg-cover"
        style={{ backgroundImage: `url(${contactBanner})` }}
      >
        <div className="container">
          <div className="breadcrumb-inner">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <h1 className="page-title">Contact Us</h1>
              </div>
              <div className="col-lg-6 text-lg-end">
                {/* <ul className='page-list'>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  {"  "}/ <li>{title}</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div >
        {" "}
        <MapDetail />
      </div>

      <Toaster position="bottom-center" reverseOrder={false} />

      {/* Contact form */}
      <div className="contact-area pd-top-20 pd-bottom-120">
        <div className="container">
          <div className="contact-page-inner bg-gray">
            <div className="section-title mb-4 pb-2">
              <h2 className="title">Get In Touch</h2>
              <p className="content mb-0">
                We’re happy to answer any questions you may have and help you
                determine which of our services best fit your needs.
              </p>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <div className="row">
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder=" Name."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="subject"
                      name="subject"
                      type="text"
                      value={formData.subject}
                      onChange={handleChange}
                      placeholder="Subject."
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="single-input-inner">
                    <textarea
                      name="message"
                      id="message"
                      value={formData.message}
                      onChange={handleChange}
                      cols="1"
                      rows="5"
                      placeholder="Message ..."
                      required
                    />
                  </div>
                </div>
                {/* reCAPTCHA */}
                <div className="col-12">
                  <ReCAPTCHA
                    sitekey="6LfHHcwpAAAAADQ04Kjgsvyx0hEZ1AkgfXPXQyzi"
                    onChange={handleRecaptchaChange}
                  />
                </div>
                {/* Submit button */}
                <div className="col-12 text-center">
                  <button className="btn btn-base border-radius-5" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactMain;
